.welcome-section {
    height: fit-content;
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* border: 2px solid #000; */
    /* margin-top: 100px; */
}

.welcome-section>h3 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('./images/asset\ 3.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 65px;
    background-position: center;
    font-weight: 500;
}

.welcome-section h2 {
    font-size: 40px;
}

.welcome-cards {
    height: fit-content;
    width: fit-content;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 40px;
    padding: 0px 40px;
}

.welcome-card {
    width: 250px;
    height: 340px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.welcome-card:hover {
    transform: translateY(-10px);
}

.welcome-card p {
    font-size: 14px;
}

.welcome-card .image-container {
    position: relative;
    width: fit-content;
}

.welcome-card .first-image {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.welcome-card .second-image {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease;
}

.welcome-card:hover .first-image {
    opacity: 0;
}

.welcome-card:hover .second-image {
    opacity: 1;
}


/* Responsive */
/*  768px and 920px */
@media (min-width: 768px) and (max-width: 920px) {
    .welcome-section {
        margin-top: 100px;
    }
}

/*  628px and 768px */
@media (min-width: 628px) and (max-width: 768px) {
    .welcome-section {
        margin-top: 100px;
    }
}

/*  320px and 628px */
@media (min-width: 320px) and (max-width: 628px) {
    .welcome-section {
        margin-top: 100px;
    }

    .welcome-section>h2 {
        font-size: 25px;
    }

    .welcome-cards {
        padding: 0px 20px;
    }

    .welcome-card {
        width: 300px;
    }
}