.banners-section {
    width: 100%;
    height: fit-content;
    background-color: #fff;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banners-section img {
    width: 100%;
}