.header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    /* background-color: #fff; */
    z-index: 999;
    transition: all 0.5s ease;
    /* border: 2px solid #000; */
    background-image: url('./images/dryfruit1.png');
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: right;
}

.navbar-banner {
    width: 100%;
    height: 30px;
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.left-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.left-banner a {
    color: #fff;
}

.left-banner>.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
}

.left-banner>.email {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
}

.background {
    background-color: #fff;
}

.header.active {
    background-color: #F3CA52;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.navbar-banner.active {
    background-color: #ed1b24;
}

.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.navbar .logo img {
    width: 140px;
    cursor: pointer;
}

.navbar .menu {
    display: flex;
    list-style: none;
    gap: 20px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
}

.navbar .menu li {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.navbar .menu li:hover {
    transform: translateY(-5px);
}

.menu li h3 {
    font-size: 18px;
    font-weight: 600;
}

.about-dropdown {
    position: relative;
}

.ceo-dropdown {
    width: 100px;
    position: absolute;
    /* transform: translateY(-80px); */
    /* display: none; */
    transition: all 0.5s ease-in-out;
    background-color: #ed1b24;
    color: #fff;
    padding-left: 5px;
    border-radius: 5px;
}

.about-dropdown:hover .ceo-dropdown {
    /* transform: translateY(0px); */
    /* display: block; */
}

.selected::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #ed1b24;
    height: 3px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 55px;
}

.navbar .search-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.navbar .search-cart>i {
    font-size: 25px;
    cursor: pointer;
}

.navbar .search-cart>button {
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: #ed1b24;
    color: #fff;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: all 0.3s ease-in-out;
}

.navbar .search-cart>button:hover {
    transform: translateY(-5px);
}

#check:checked ~ .menu {
    display: flex;
}

.search-cart label {
    display: none;
}

.navbar input[type="checkbox"] {
    display: none;
}

/* Responsive */
/* 628px and 768px  */
@media (min-width: 320px) and (max-width: 768px) {

    .header {
        background-size: cover;
        background-position: top;
    }

    .navbar .menu {
        position: absolute;
        top: 80px;
        right: 0px;
        background-color: #81b03f;
        color: #fff;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        font-size: 28px;
        gap: 30px;
        align-items: center;
        justify-content: start;
        padding-top: 40px;
        display: none;
    }

    .menu li h3 {
        font-size: 28px;
    }

    .ceo-dropdown {
        width: 150px;
    }

    .search-cart label {
        display: block;
        font-size: 25px;
    }

}

/* 100px and 480px  */
@media (min-width: 100px) and (max-width: 480px) {
    .navbar .search-cart>i:first-child {
        display: none;
    }
}