.shop-section {
    width: 100%;   
    height: fit-content;
    background-color: #fff;
    margin-top: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('./images/dryfruit1.png');
    background-size: contain;
    background-position: left;
}

.shop-section>h2 {
    margin-top: 30px;
    font-size: 40px;
}

.product-list {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px 40px;
}

.product-search {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 5px;
    border: 1.5px solid #000;
    border-right: none;

}

.product-search input {
    width: 260px;
    height: 40px;
    padding-left: 10px;
    outline: none;
    background-color: transparent;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.product-search button {
    width: fit-content;
    padding: 0px 10px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    background-color: #ed1b24;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.no-data {
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/*  320px and 628px */
@media (min-width: 320px) and (max-width: 628px)  {
    .shop-section {
        background-size: 1000px;
        background-position: bottom;
    }
}